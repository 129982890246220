<app-header [title]="'add-smart-task-dialog.title' | translate"
  [subtitle]="'add-smart-task-dialog.subtitle' | translate" [showNavigateBack]="true"
  (navigateBackEvent)="navigateBack()"></app-header>

<div class="dialog-wrapper">

  @if (profileError()) {
  <app-error-img></app-error-img>
  }

  @if (newSmartTaskToDo) {
  <form [formGroup]="form">

    <!-- Name -->
    <p>
      <mat-form-field appearance="outline" color="accent" subscriptSizing="dynamic">
        <mat-label>{{ 'add-smart-task-dialog.name' | translate }}</mat-label>
        <input matInput formControlName="name" required>
        @if (form.controls.name.touched && form.controls.name.invalid && form.controls.name.errors?.['required']) {
        <mat-error>
          <span>{{ 'add-smart-task-dialog.required' | translate }}</span>
        </mat-error>
        }
      </mat-form-field>
    </p>

    <!-- Description -->
    <p>
      <mat-form-field appearance="outline" color="accent" subscriptSizing="dynamic">
        <mat-label>{{ 'add-smart-task-dialog.description' | translate }}</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
    </p>
    <br>

    <!-- Start date -->
    <p>
      <mat-form-field appearance="outline" color="accent" subscriptSizing="dynamic" style="margin-bottom: 6px;">
        <mat-label>{{ 'add-smart-task-dialog.date' | translate }}</mat-label>
        <input matInput [matDatepicker]="picker" readonly formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker-toggle matSuffix (click)="clearStartDate()">
          <mat-icon matDatepickerToggleIcon><fa-icon [icon]="faXmark"></fa-icon></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker touchUi #picker></mat-datepicker>
      </mat-form-field>
    </p>

    <!-- Time and duration -->
    <div class="time-container">

      <mat-form-field class="time" appearance="outline" color="accent" subscriptSizing="dynamic" style="margin-bottom: 16px;">
        <mat-label>{{ 'add-smart-task-dialog.start-time' | translate }}</mat-label>
        <input type="time" matInput formControlName="startTime"
          placeholder="{{ 'add-smart-task-dialog.start-time' | translate }}">
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent" class="margin-left" subscriptSizing="dynamic">
        <mat-label>{{ 'add-smart-task-dialog.duration' | translate }}</mat-label>
        <input matInput #durationInput type="number" formControlName="durationInMinutes"
          placeholder="{{ 'add-smart-task-dialog.duration' | translate }}">
        <mat-hint align="end">
          {{ 'add-smart-task-dialog.duration-hint' | translate }}
        </mat-hint>
      </mat-form-field>
    </div>

    <div style="margin: 30px 0;">
    <!-- Recurrence -->
    <app-recurrence [recurrencePattern]="newSmartTaskToDo.recurrencePattern"
      (recurrenceUpdatedEvent)="editRecurrence($event)"></app-recurrence>
    </div>

    <!-- Assign to -->

    @if (profiles(); as profiles) {
    <p>
      <mat-form-field appearance="outline" color="accent" subscriptSizing="dynamic">
        <mat-label>{{ 'add-smart-task-dialog.who' | translate }}</mat-label>
        <mat-select formControlName="subscriberIds" multiple>
          <mat-select-trigger>
            {{ form.controls.subscriberIds.value | selectedProfile: profiles }}

            @if (form.controls.subscriberIds.value && form.controls.subscriberIds.value.length > 1) {
            <span class="additional-selection">
              (+{{form.controls.subscriberIds.value.length - 1}} {{form.controls.subscriberIds.value?.length === 2
              ? ('add-smart-task-dialog.other' | translate) : ('add-smart-task-dialog.others' | translate)}})
            </span>
            }
          </mat-select-trigger>
          @for (profile of profiles; track profile.id) {
          <mat-option [value]="profile.id">{{ profile.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </p>
    }

    <br>
    <!-- Save button -->
     <div class="wrap" style="margin-bottom: 16px;">
    <app-save-button [text]="'add-smart-task-dialog.save-task' | translate" [disabled]="!form.valid"
      (saveEvent)="submit()">
    </app-save-button>
  </div>
  </form>
  }
</div>