<app-header [showNavigateBack]="true" [title]="'add-smart-task-dialogs.smarttask-title' | translate" [subtitle]="'add-smart-task-dialogs.smarttask-subtitle' | translate"
  (navigateBackEvent)="navigateBack()">
</app-header>

<div class="dialog-wrapper">

    <p class="text">{{ 'add-smart-task-dialogs.smart-task-text' | translate }}</p>

    <ul>
        <li class="text">{{ 'add-smart-task-dialogs.smart-task-bullet-one' | translate }}</li>
        <li class="text">{{ 'add-smart-task-dialogs.smart-task-bullet-two' | translate }}</li>
    </ul>

    <h2>{{ 'add-smart-task-dialogs.add-smart-task' | translate }}</h2>

    <div class="parent">
        @if (input.showPackBag) {
            <fam-button-secondary [btnName]="'add-smart-task-dialogs.pack-bag' | translate" (buttonClickedEvent)="openBagSheet()"></fam-button-secondary>
            <img [src]="srcOne" alt="example of the smart task 'pack school bag' on the assignments' list" height="60px" width="auto" (click)="navigateTaskExample('600ms', '300ms', srcOne)">
        }
        
        @if (input.showHomework) {
            <fam-button-secondary [btnName]="'add-smart-task-dialogs.check-homework' | translate" (buttonClickedEvent)="openHomeworkSheet()"></fam-button-secondary>
            <img [src]="srcTwo" alt="example of the smart task 'pack school bag' on the assignments' list" height="60px" width="auto" (click)="navigateTaskExample('600ms', '300ms', srcTwo)">
        }
    </div>
</div>