import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { TimetableLinkSettings } from 'src/app/core/services/to-do/timetable-link-settings.data';
import { ETimetableLinkDay } from 'src/app/core/services/to-do/timetable-link-day.enum';
import { ESmartLinkType } from 'src/app/core/services/to-do/smart-link-type.enum';

@Component({
  selector: 'app-smart-task-day-picker-dialog',
  templateUrl: './smart-task-day-picker-dialog.component.html',
  styleUrl: './smart-task-day-picker-dialog.component.scss'
})
export class SmartTaskDayPickerDialogComponent {
  ETimetableLinkDay = ETimetableLinkDay;
  ESmartLinkType = ESmartLinkType;

  title = toSignal(this.getTitle());

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SmartTaskDayPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public taskType: ESmartLinkType,
    private translateService: TranslateService
  ) {}

  chooseDay(day: ETimetableLinkDay) {
    const linkSettings: TimetableLinkSettings = {
      day: day
    };
    this.dialogRef.close(linkSettings);
  }

  navigateBack(): void {
    this.dialogRef.close();
  }


  private getTitle() {
    if (this.taskType === ESmartLinkType.PackBag) {
      return this.translateService.get('add-smart-task-dialogs.pack-bag');
    }
    else if (this.taskType === ESmartLinkType.Homework) {
      return this.translateService.get('add-smart-task-dialogs.check-homework');
    }
    else {
      return of('Choose day');
    }
  }
}
