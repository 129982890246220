import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-saved-timetable-message-dialog',
  templateUrl: './saved-timetable-message-dialog.component.html',
  styleUrl: './saved-timetable-message-dialog.component.scss'
})
export class SavedTimetableMessageDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<SavedTimetableMessageDialogComponent>,
    private router: Router
  ) { }

  close() {
    this.dialogRef.close();
    this.router.navigateByUrl(`calendar/timetables`);
  }
}
