import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCalendarClock, faPlus, faSquareCheck, faXmark, faRepeat } from '@fortawesome/pro-light-svg-icons';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { TicksService } from 'src/app/core/services/ticks/ticks.service';
import { StartTimeService } from 'src/app/core/services/start-time/start-time.service';
import { EToDoType } from 'src/app/core/services/to-do/to-do-type.enum';
import { ProfileService } from 'src/app/core/services/profile/profile.service';
import { RecurrencePattern } from 'src/app/core/services/recurrence/recurrence-pattern.data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NewToDo } from 'src/app/core/services/to-do/new-to-do.data';

@Component({
  selector: 'app-add-smart-task-dialog',
  templateUrl: './add-smart-task-dialog.component.html',
  styleUrls: ['./add-smart-task-dialog.component.scss']
})
export class AddSmartTaskDialogComponent implements OnInit {
  faPlus = faPlus;
  faSquareCheck = faSquareCheck;
  faCalendarClock = faCalendarClock;
  faRepeat = faRepeat;
  faXmark = faXmark;
  EToDoType = EToDoType;

  form!: UntypedFormGroup;

  // Profiles
  profiles = this.profileService.profiles;
  profileError = this.profileService.profilesError;

  constructor(
    @Inject(MAT_DIALOG_DATA) public newSmartTaskToDo: NewToDo,
    public dialogRef: MatDialogRef<AddSmartTaskDialogComponent>,
    private fb: UntypedFormBuilder,
    private dateAdapter: DateAdapter<any>,
    private translateService: TranslateService,
    private ticksService: TicksService,
    private profileService: ProfileService,
    private startTimeService: StartTimeService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [null, Validators.required],
      description: null,
      startTime: null,
      durationInMinutes: null,
      subscriberIds: [[], Validators.required],
      startDate: new Date()
    });

    if (this.newSmartTaskToDo) {
      // update form values
      const smartTask = this.newSmartTaskToDo.tasks[0];

      this.form.patchValue({
        name: smartTask.name,
        description: smartTask.description,
        startTime: smartTask.startHour ? this.startTimeService.getStartTimeStr(smartTask.startHour, smartTask.startMinute) : null,
        durationInMinutes: this.getMinutes(smartTask.durationInSecs),
        subscriberIds: this.newSmartTaskToDo.subscriberIds
      });
    }

    this.dateAdapter.setLocale(this.translateService.currentLang);
  }

  clearStartDate() {
    this.form.patchValue({
      startDate: null
    });
  }

  editRecurrence(recurrencePattern: RecurrencePattern | null): void {
    this.form.markAsDirty();
    this.newSmartTaskToDo.recurrencePattern = recurrencePattern;
  }

  submit() {
    // name
    this.newSmartTaskToDo.tasks[0].name = this.form.controls.name.value;

    // desc
    this.newSmartTaskToDo.tasks[0].description = this.form.controls.description.value;

    // start time
    if (this.form.controls.startTime.value) {
      const startTimeArr = this.form.controls.startTime.value.split(':');
      this.newSmartTaskToDo.tasks[0].startHour = Number(startTimeArr[0]);
      this.newSmartTaskToDo.tasks[0].startMinute = Number(startTimeArr[1]);
    } else {
      this.newSmartTaskToDo.tasks[0].startHour = null;
      this.newSmartTaskToDo.tasks[0].startMinute = null;
    }

    // duration
    if (this.form.controls.durationInMinutes.value) {
      this.newSmartTaskToDo.tasks[0].durationInSecs = Number(this.form.controls.durationInMinutes.value) * 60;
    }

    // subscribers
    this.newSmartTaskToDo.subscriberIds = this.form.controls.subscriberIds.value;

    // start date
    if (this.form.controls.startDate.value) {

      const startDateTicks = this.ticksService.getTicks(this.form.controls.startDate.value);
      this.newSmartTaskToDo.start = startDateTicks;
    }

    // set order number
    this.newSmartTaskToDo.tasks[0].orderNo = 1;

    // navigate back
    this.dialogRef.close(this.newSmartTaskToDo);
  }

  navigateBack(): void {
    this.dialogRef.close();
  }


  private getMinutes(inSeconds: number | null) {
    return inSeconds ? inSeconds / 60 : null
  }
}
