import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { faFloppyDisk } from '@fortawesome/pro-light-svg-icons';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { SchoolSubject } from 'src/app/core/services/timetable/school-subject.data';
import { AddSubjectSheetInput } from './add-subject-sheet-input.model';

@Component({
  selector: 'app-add-subject-sheet',
  templateUrl: './add-subject-sheet.component.html',
  styleUrl: './add-subject-sheet.component.scss'
})
export class AddSubjectSheetComponent {

  faFloppyDisk = faFloppyDisk;
  faDelete = faTrash;

  form = new FormGroup({
    startTime: new FormControl('', Validators.required), // Our existing name FormControl
    endTime: new FormControl('', Validators.required), // Our existing name FormControl
    name: new FormControl('', Validators.required), // A new FormControl for the email
    room: new FormControl(''), // A new FormControl for the email
    teacher: new FormControl(''), // A new FormControl for the email
  });

  newSubject: SchoolSubject | null = null;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private input: AddSubjectSheetInput,
    private bottomSheetRef: MatBottomSheetRef<AddSubjectSheetComponent>
  ) { }

  handleSubmit() {
    const startTimeArr = this.form.controls.startTime.value!.split(':');
    const startHour = startTimeArr[0];
    const startMinute = startTimeArr[1];

    const endTimeArr = this.form.controls.endTime.value!.split(':');
    const endHour = endTimeArr[0];
    const endMinute = endTimeArr[1];

    this.newSubject = {
      id: -1,
      timetableId: this.input.timetableId,
      dayOfWeek: this.input.dayOfWeek,
      startHour: Number(startHour),
      startMinute: Number(startMinute),
      endHour: Number(endHour),
      endMinute: Number(endMinute),
      name: this.form.value.name!,
      room: this.form.value.room!,
      teacher: this.form.value.teacher!
    };
    this.bottomSheetRef.dismiss(this.newSubject);
  }
}
