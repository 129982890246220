<mat-toolbar color="primary" class="bottom-sheet-title">
    <span>{{ 'add-smart-task-dialogs.pack-bag' | translate }}</span>
</mat-toolbar>

<div class="sheet-wrapper">

    <div class="flex-wrapper">
        
        <div class="btn-text-container">
            <fam-button-secondary
                [btnName]="'add-smart-task-dialogs.add-to-existing' | translate"
                (buttonClickedEvent)="chooseExistingTask()"
            ></fam-button-secondary>
            <p>{{ 'add-smart-task-dialogs.add-to-existing-text' | translate }}</p>
        </div>
        <div class="btn-text-container">
            <fam-button-secondary
                [btnName]="'add-smart-task-dialogs.add-new-task' | translate"
                (buttonClickedEvent)="chooseNewTask()"
            ></fam-button-secondary>

            <p>{{ 'add-smart-task-dialogs.add-new-task-text' | translate }}</p>
            <p>{{ 'add-smart-task-dialogs.add-new-task-text2' | translate }}</p>
        </div>
    </div>
</div>