import { Component, computed, Inject, signal } from '@angular/core';
import { faFloppyDisk, faPen, faArrowLeft, faPlus } from '@fortawesome/pro-light-svg-icons';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditSubjectSheetComponent } from '../components/edit-subject-sheet/edit-subject-sheet.component';
import { AddSubjectSheetComponent } from '../components/add-subject-sheet/add-subject-sheet.component';
import { UnsavedChangesSheetComponent } from 'src/app/shared/components/unsaved-changes-sheet/unsaved-changes-sheet.component';
import { EDayOfWeek } from 'src/app/core/services/recurrence/day-of-week.enum';
import { Timetable } from 'src/app/core/services/timetable/timetable.data';
import { SchoolSubject } from 'src/app/core/services/timetable/school-subject.data';
import { AddSubjectSheetInput } from '../components/add-subject-sheet/add-subject-sheet-input.model';
import { SubjectAction } from '../components/edit-subject-sheet/subject.action';
import { TimetableService } from 'src/app/core/services/timetable/timetable.service';

@Component({
  selector: 'app-edit-timetable-dialog',
  templateUrl: './edit-timetable-dialog.component.html',
  styleUrl: './edit-timetable-dialog.component.scss'
})
export class EditTimetableDialogComponent {
  faArrowLeft = faArrowLeft;
  faPlus = faPlus;
  faFloppyDisk = faFloppyDisk;
  faPen = faPen;

  isEdited = false;

  editedSubjects = signal<SchoolSubject[]>([... this.timetable.schoolSubjects]);

  selectedDayOfWeek = signal<EDayOfWeek>(EDayOfWeek.Monday);
  selectedSubjects = computed(() => {
    const subjects = this.editedSubjects().filter(subject => subject.dayOfWeek === this.selectedDayOfWeek());
    return this.timetableService.sortSubjects([...subjects]);
  });

  constructor(
    private dialogRef: MatDialogRef<EditTimetableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public timetable: Timetable,
    private timetableService: TimetableService,
    private bottomSheet: MatBottomSheet
  ) { }

  navigateBack(): void {
    if (this.isEdited) {
      const bottomSheetRef = this.bottomSheet.open(UnsavedChangesSheetComponent);
  
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  next() {
    let nextDayNo = Number(this.selectedDayOfWeek());
    if (nextDayNo < 6) {
      nextDayNo++;
    } else if (nextDayNo === 6) {
      nextDayNo = 0;
    }
    const nextDay = nextDayNo as EDayOfWeek;

    this.selectedDayOfWeek.set(nextDay);
  }

  previous() {
    let previousDayNo = Number(this.selectedDayOfWeek());
    if (previousDayNo > 0) {
      previousDayNo--;
    } else if (previousDayNo === 0) {
      previousDayNo = 6;
    }
    const previousDay = previousDayNo as EDayOfWeek;

    this.selectedDayOfWeek.set(previousDay);
  }

  editSubject(subject: SchoolSubject) {
    const originalIndex = this.editedSubjects().findIndex(s => s.startHour === subject.startHour && s.name === subject.name);

    const bottomSheetRef = this.bottomSheet.open(EditSubjectSheetComponent, { data: subject});

    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result) {
        const action = result as SubjectAction;
        this.isEdited = true;

        if (action.action === 'update') {
          this.editedSubjects.update((subjects: SchoolSubject[]) => {
            subjects[originalIndex] = action.subject;
            return [...subjects];
          });
        }
        else if (action.action === 'delete') {
          this.editedSubjects.update((subjects: SchoolSubject[]) => {
            subjects[originalIndex] = action.subject;

            const reducedSubjects = subjects.filter(s => !(s.startHour === subject.startHour && s.name === subject.name));
            return [...reducedSubjects];
          });
        }
      }
    });
  }

  addSubjectToTimeTable() {
    const input: AddSubjectSheetInput = {
      dayOfWeek: this.selectedDayOfWeek(),
      timetableId: this.timetable.id
    };

    const bottomSheetRef = this.bottomSheet.open(AddSubjectSheetComponent, {
      data: input
    });
 
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result) {
        const subject = result as SchoolSubject;

        this.editedSubjects.update((subjects: SchoolSubject[]) => {
          subjects.push(subject);
          return [...subjects];
        });

        this.isEdited = true;
      }
    });
  }

  saveTimetable() {
    this.timetable.schoolSubjects = this.editedSubjects();
    this.dialogRef.close(this.timetable);
  }
}
