<app-header [showNavigateBack]="true" [title]="title()"
    [subtitle]="'add-smart-task-dialogs.smarttask-title' | translate" (navigateBackEvent)="navigateBack()">
</app-header>

<div class="dialog-wrapper">
    @if (taskType === ESmartLinkType.PackBag) {
        <h2>{{ 'add-smart-task-dialogs.day-picker-title' | translate }}</h2>
    }
    @else if (this.taskType === ESmartLinkType.Homework) {
        <h2>{{ 'add-smart-task-dialogs.day-picker-title2' | translate }}</h2>
    }

    <section class="text-btn-container">
        <div>
            <fam-button-primary (buttonClickedEvent)=" chooseDay(ETimetableLinkDay.DayBefore)"
                [btnName]="'add-smart-task-dialogs.day-before' | translate"></fam-button-primary>
        </div>

          @if (taskType === ESmartLinkType.PackBag) {
        <p>{{ 'add-smart-task-dialogs.day-before-text' | translate }}</p>
    }
    @else if (this.taskType === ESmartLinkType.Homework) {
        <p>{{ 'add-smart-task-dialogs.day-before-text-homework' | translate }}</p>
    }
        <br>
        <fam-button-primary (buttonClickedEvent)=" chooseDay(ETimetableLinkDay.OnDay)"
            [btnName]="'add-smart-task-dialogs.on-day' | translate"></fam-button-primary>
            @if (taskType === ESmartLinkType.PackBag) {
                <p>{{ 'add-smart-task-dialogs.on-day-text' | translate }}</p>
            }
            @else if (this.taskType === ESmartLinkType.Homework) {
                <p>{{ 'add-smart-task-dialogs.on-day-text-homework' | translate }}</p>
            }
    </section>
</div>