<app-header [showNavigateBack]="true" [title]="'add-smart-task-dialogs.title' | translate"
    [subtitle]="'add-smart-task-dialogs.smarttask-title' | translate" (navigateBackEvent)="navigateBack()">
</app-header>

<div class="dialog-wrapper">

    <!-- <h2>{{ 'add-smart-task-dialogs.add-to-existing' | translate }}</h2> -->

    <div class="card">
        <p class="title">{{ 'add-smart-task-dialogs.pick-task' | translate }}</p>

        <ul>
            @for (toDo of toDos; track toDo.id) {
                <li (click)="toDo.type === EToDoType.Single ? chooseTask(toDo.tasks[0].id) : toggleToDo(toDo.id, toDo.expanded)">
                    <div class="an-element">
                        <p class="text">{{ toDo.name }}</p>

                        @if (toDo.type === EToDoType.List) {
                            @if (toDo.expanded) {
                                <fa-icon [icon]="faChevronDown" size="xl"></fa-icon>
                            }
                            @else {
                                <fa-icon [icon]="faChevronRight" size="xl"></fa-icon>
                            }
                        }
                    </div>
    
                    @if (toDo.type === EToDoType.List && toDo.expanded) {
                        <ul class="sub-list" style="margin-top: 8px; max-width: 80%; align-items: end; margin-right: auto;">

                            @for (task of toDo.tasks; track task.id) {
                                <li class="sub-list-li" (click)="chooseTask(task.id)">
                                    <div class="an-sub-element">
                                        <p class="text">{{ task.name }}</p>
                                    </div>
                                </li>
                            }
                        </ul>
                    }
                </li>
            }
        </ul>
    </div>
</div>