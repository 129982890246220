import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, computed, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Profile } from 'src/app/core/services/profile/profile.data';
import { ProfileService } from 'src/app/core/services/profile/profile.service';
import { EUserType } from 'src/app/core/services/profile/user-type.enum';

@Component({
  selector: 'app-add-calendar-sheet',
  templateUrl: './add-calendar-sheet.component.html',
  styleUrls: ['./add-calendar-sheet.component.scss']
})
export class AddCalendarSheet implements OnInit {

  childProfiles = computed(() => {
    let childProfiles: Profile[] = [];
    const profiles = this.profileService.profiles();
    
    if (profiles && profiles.length > 0) {
      childProfiles = profiles.filter(p => p.userType !== EUserType.Adult);
    }
    return childProfiles;
  });

  constructor(
    private bottomSheetRef: MatBottomSheetRef<AddCalendarSheet>,
    private profileService: ProfileService,
    private overlayContainer: OverlayContainer
  ) {}

  ngOnInit(): void {
    this.overlayContainer.getContainerElement().classList.remove(`${this.profileService.alternativeTheme()}`);
    this.overlayContainer.getContainerElement().classList.add(`${this.profileService.theme()}`);
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }
}
