import { Component, Inject, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { faFloppyDisk } from '@fortawesome/pro-light-svg-icons';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { StartTimeService } from 'src/app/core/services/start-time/start-time.service';
import { SchoolSubject } from 'src/app/core/services/timetable/school-subject.data';
import { SubjectAction } from './subject.action';


@Component({
  selector: 'app-edit-subject-sheet',
  templateUrl: './edit-subject-sheet.component.html',
  styleUrl: './edit-subject-sheet.component.scss'
})
export class EditSubjectSheetComponent implements OnInit {
  faFloppyDisk = faFloppyDisk;
  form!: UntypedFormGroup;
  faDelete = faTrash;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public subject: SchoolSubject,
    private bottomSheetRef: MatBottomSheetRef<EditSubjectSheetComponent>,
    private startTimeService: StartTimeService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      startTime: [this.startTimeService.getStartTimeStr(this.subject.startHour, this.subject.startMinute), Validators.required],
      endTime: [this.startTimeService.getStartTimeStr(this.subject.endHour, this.subject.endMinute), Validators.required],
      name:[this.subject.name, Validators.required],
      room: this.subject.room,
      teacher: this.subject.teacher,
    });
  }

  deleteSubject() {
    const action: SubjectAction = {
      subject: this.subject,
      action: 'delete'
    };

    this.bottomSheetRef.dismiss(action);
  }

  handleSubmit() {
    if (this.form.controls.startTime.value) {
      const startTimeArr = this.form.controls.startTime.value.split(':');
      const startHour = Number(startTimeArr[0]);
      const startMinute = Number(startTimeArr[1]);
      this.subject.startHour = startHour;
      this.subject.startMinute = startMinute;
    }

    if (this.form.controls.endTime.value) {
      const endTimeArr = this.form.controls.endTime.value.split(':');
      const endHour = Number(endTimeArr[0]);
      const endMinute = Number(endTimeArr[1]);
      this.subject.endHour = endHour;
      this.subject.endMinute = endMinute;
    }

    this.subject.name = this.form.controls.name.value;
    this.subject.room = this.form.controls.room.value;
    this.subject.teacher = this.form.controls.teacher.value;

    const action: SubjectAction = {
      subject: this.subject,
      action: 'update'
    };

    this.bottomSheetRef.dismiss(action);
  }
}