import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { EToDoType } from 'src/app/core/services/to-do/to-do-type.enum';
import { ToDo } from 'src/app/core/services/to-do/to-do.data';

@Component({
  selector: 'app-smart-task-add-to-existing-dialog',
  templateUrl: './smart-task-add-to-existing-dialog.component.html',
  styleUrl: './smart-task-add-to-existing-dialog.component.scss'
})
export class SmartTaskAddToExistingDialogComponent {
  faChevronDown = faChevronDown;
  faChevronRight = faChevronRight;

  EToDoType = EToDoType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public toDos: ToDo[],
    public dialogRef: MatDialogRef<SmartTaskAddToExistingDialogComponent>,
  ) { }

  toggleToDo(toDoId: number, expanded: boolean): void {
    const index = this.toDos.findIndex(t => t.id === toDoId);
    this.toDos[index].expanded = !expanded;
  }

  chooseTask(taskId: number) {
    this.dialogRef.close(taskId);
  }

  navigateBack(): void {
    this.dialogRef.close();
  }
}
