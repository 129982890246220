<div class="background">
    <div class="card">
        <!-- <img src="../../../assets/images/timetable/schemabg.png"
        style="height: 60px; width: auto;" alt=""> -->
        <img src="../../../assets/images/timetable/timetable-icon.png" alt="" height="100px;" width="auto"> 
        <p>{{ 'add-timetable.save-message' | translate }}</p>

        <fam-button-cta [btnName]="'add-timetable.ok' | translate" (buttonClickedEvent)="close()"></fam-button-cta>

        <!-- <button class="save-btn" (click)="close()">{{ 'add-timetable.ok' | translate }}</button> -->

    </div>
      <img src="../../../assets/images/summer-wheel/d.png"
        alt="" class="tricks"> 
</div>