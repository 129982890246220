import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SmartTaskAction } from '../models/smart-task.action';

@Component({
  selector: 'app-add-smart-task-homework-sheet',
  templateUrl: './add-smart-task-homework-sheet.component.html',
  styleUrl: './add-smart-task-homework-sheet.component.scss'
})
export class AddSmartTaskHomeworkSheetComponent {

  constructor(
    private bottomSheetRef: MatBottomSheetRef<AddSmartTaskHomeworkSheetComponent>
  ) { }

  chooseExistingTask() {
    const action: SmartTaskAction = {
      action: 'existing'
    };
    this.bottomSheetRef.dismiss(action);
  }

  chooseNewTask() {
    const action: SmartTaskAction = {
      action: 'new'
    };
    this.bottomSheetRef.dismiss(action);
  }
}
