import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SmartTaskExampleDialog } from './smart-task-example-dialog.model';

@Component({
  selector: 'app-smart-task-example-dialog',
  templateUrl: './smart-task-example-dialog.component.html',
  styleUrl: './smart-task-example-dialog.component.scss'
})
export class SmartTaskExampleDialogComponent {


  constructor(
    public dialogRef: MatDialogRef<SmartTaskExampleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SmartTaskExampleDialog
  ) { }

  onCancel(): void {
    this.dialogRef.close();
  }
}
