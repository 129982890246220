<mat-toolbar color="primary" class="bottom-sheet-title">
    <span>{{ 'add-subject-sheet.title' | translate }}</span>
</mat-toolbar>

<div class="sheet-wrapper">

    <form [formGroup]="form" (ngSubmit)="handleSubmit()" class="form-styling">

        <div class="time-wrapper">
            <div class="form-item">
                <input type="time" formControlName="startTime" id="startTime" placeholder="e.g. 8:00-9:00" class="form-input">
                <label for="startTime" class="form-label">{{ 'add-subject-sheet.startTime' | translate }}</label>

                @if (form.controls.startTime.touched && form.controls.startTime.invalid && form.controls.startTime.errors?.['required']) {
                    <mat-error>
                      <span>{{ 'add-subject-sheet.required' | translate }}</span>
                    </mat-error>
                }
            </div>

            <div class="form-item">
                <input type="time" formControlName="endTime" id="endTime" placeholder="e.g. 8:00-9:00" class="form-input">
                <label for="endTime" class="form-label">{{ 'add-subject-sheet.endTime' | translate }}</label>

                @if (form.controls.endTime.touched && form.controls.endTime.invalid && form.controls.endTime.errors?.['required']) {
                    <mat-error>
                      <span>{{ 'add-subject-sheet.required' | translate }}</span>
                    </mat-error>
                }
            </div>
        </div>

        <div class="form-item-full">
            <input type="text" formControlName="name" id="name" class="form-input-full" autocomplete="off">
            <label for="name" class="form-label">{{ 'add-subject-sheet.subject' | translate }}</label>

            @if (form.controls.name.touched && form.controls.name.invalid && form.controls.name.errors?.['required']) {
                <mat-error>
                  <span>{{ 'add-subject-sheet.required' | translate }}</span>
                </mat-error>
            }
        </div>

        <div class="time-wrapper">
            <div class="form-item">
                <input type="text" formControlName="room" id="roome" class="form-input" autocomplete="off">
                <label for="room" class="form-label">{{ 'add-subject-sheet.room' | translate }}</label>
            </div>

            <div class="form-item">
                <input type="text" formControlName="teacher" id="teacher" class="form-input" autocomplete="off">
                <label for="teacher" class="form-label">{{ 'add-subject-sheet.teacher' | translate }}</label>
            </div>
        </div>

        <div class="btn-wrapper">
            <fam-button-cta [disabled]="!form.valid" [btnName]="'add-subject-sheet.save' | translate" [icon]="faFloppyDisk"></fam-button-cta>
        </div>
    </form>
</div>