import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SmartTaskExampleDialogComponent } from '../smart-task-example-dialog/smart-task-example-dialog.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AddSmartTaskHomeworkSheetComponent } from '../add-smart-task-homework-sheet/add-smart-task-homework-sheet.component';
import { SmartTaskExampleDialog } from '../smart-task-example-dialog/smart-task-example-dialog.model';
import { AddSmartTaskPackbagSheetComponent } from '../add-smart-task-packbag-sheet/add-smart-task-packbag-sheet.component';
import { SmartTaskAction } from '../models/smart-task.action';
import { SmartTaskAddToExistingDialogComponent } from '../smart-task-add-to-existing-dialog/smart-task-add-to-existing-dialog.component';
import { SmartTaskDayPickerDialogComponent } from '../smart-task-day-picker-dialog/smart-task-day-picker-dialog.component';
import { TimetableLinkSettings } from 'src/app/core/services/to-do/timetable-link-settings.data';
import { UpsertedSmartTask } from 'src/app/core/services/to-do/upserted-smart-task.data';
import { AddSmartTaskDialogComponent } from '../add-smart-task-dialog/add-smart-task-dialog.component';
import { NewToDo } from 'src/app/core/services/to-do/new-to-do.data';
import { NewToDoTask } from 'src/app/core/services/to-do/new-to-do-task.data';
import { RecurrenceService } from 'src/app/core/services/recurrence/recurrence.service';
import { EToDoType } from 'src/app/core/services/to-do/to-do-type.enum';
import { ETimetableLinkDay } from 'src/app/core/services/to-do/timetable-link-day.enum';
import { TranslateService } from '@ngx-translate/core';
import { ESmartLinkType } from 'src/app/core/services/to-do/smart-link-type.enum';
import { AddSmartTasksDialogInput } from './add-smart-tasks-dialog-input.model';

@Component({
  selector: 'app-add-smart-tasks-dialog',
  templateUrl: './add-smart-tasks-dialog.component.html',
  styleUrl: './add-smart-tasks-dialog.component.scss'
})
export class AddSmartTasksDialogComponent {
  srcOne: string = "assets/images/example1.png";
  srcTwo: string = "assets/images/example2.png";

  constructor(
    @Inject(MAT_DIALOG_DATA) public input: AddSmartTasksDialogInput,
    private dialogRef: MatDialogRef<AddSmartTasksDialogComponent>,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private recurrenceService: RecurrenceService,
    private translateService: TranslateService
  ) { }

  navigateBack(): void {
    this.dialogRef.close();
  }

  navigateTaskExample(enterAnimationDuration: string, exitAnimationDuration: string, imgSrc: string): void {
    const data: SmartTaskExampleDialog = {
      imgSrc: imgSrc
    };

    this.dialog.open(SmartTaskExampleDialogComponent, {
      panelClass: 'smart-task-dialog',
      width: '350px',
      height: 'auto',
      enterAnimationDuration,
      exitAnimationDuration,
      data: data
    });
  }

  openBagSheet() {
    // Choose between creating a new task or using an existing ...
    if (this.input.toDos && this.input.toDos.length > 0) {
      const bottomSheetRef = this.bottomSheet.open(AddSmartTaskPackbagSheetComponent);

      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result) {
          const action = result as SmartTaskAction;

          // Settings dialog
          this.choosePackBagSettingsAndTask(action);
        }
      });
    }
    else {
      // ... or only offer to create new task
      const action: SmartTaskAction = {
        action: 'new'
      };

      this.choosePackBagSettingsAndTask(action);
    }
  }

  openHomeworkSheet() {
    // Choose between creating a new task or using an existing ...
    if (this.input.toDos && this.input.toDos.length > 0) {
      const bottomSheetRef = this.bottomSheet.open(AddSmartTaskHomeworkSheetComponent);

      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result) {
          const action = result as SmartTaskAction;

          // Settings dialog
          this.chooseHomeworkSettingsAndTask(action);
        }
      });
    }
    else {
      // ... or only offer to create new task
      const action: SmartTaskAction = {
        action: 'new'
      };

      this.chooseHomeworkSettingsAndTask(action);
    }
  }


  private choosePackBagSettingsAndTask(action: SmartTaskAction) {

    const dialogRef = this.dialog.open(SmartTaskDayPickerDialogComponent, {
      panelClass: 'app-full-bleed-dialog',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false,
      data: ESmartLinkType.PackBag
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const settings = result as TimetableLinkSettings;

        // Find start hour for new task
        let startHour = 7;
        if (settings.day === ETimetableLinkDay.DayBefore) {
          startHour = 19
        };

        let smartTaskPackBag: UpsertedSmartTask = {
          taskId: null,
          linkType: ESmartLinkType.PackBag,
          linkSettings: JSON.stringify(settings),
          newSmartTaskToDo: null
        };

        // Link to task
        if (action.action === 'new') {
          this.translateService.get('add-smart-task-dialogs.pack-bag').subscribe((translation: string) => {
            this.addNewTask(smartTaskPackBag, translation, startHour, settings.day);
          });
        }
        else if (action.action === 'existing') {
          this.chooseExistingTask(smartTaskPackBag);
        }
      }
    });
  }

  private chooseHomeworkSettingsAndTask(action: SmartTaskAction) {

    const dialogRef = this.dialog.open(SmartTaskDayPickerDialogComponent, {
      panelClass: 'app-full-bleed-dialog',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false,
      data: ESmartLinkType.Homework
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const settings = result as TimetableLinkSettings;

        // Find start hour for new task
        let startHour = 7;
        if (settings.day === ETimetableLinkDay.DayBefore) {
          startHour = 19
        };

        let smartTaskHomework: UpsertedSmartTask = {
          taskId: null,
          linkType: ESmartLinkType.Homework,
          linkSettings: JSON.stringify(settings),
          newSmartTaskToDo: null
        };

        // Link to task
        if (action.action === 'new') {
          this.translateService.get('add-smart-task-dialogs.check-homework').subscribe((translation: string) => {
            const startHour = 16;
            this.addNewTask(smartTaskHomework, translation, startHour, settings.day);
          });
        }
        else if (action.action === 'existing') {
          this.chooseExistingTask(smartTaskHomework);
        }
      }
    });
  }

  private chooseExistingTask(upsertedSmartTask: UpsertedSmartTask) {
    
    const dialogRef = this.dialog.open(SmartTaskAddToExistingDialogComponent, {
      panelClass: 'app-full-bleed-dialog',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false,
      data: this.input.toDos
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Close with upserted smart task
        const taskId = result as number;
        upsertedSmartTask.taskId = taskId;

        this.dialogRef.close(upsertedSmartTask);
      }
    });
  }

  private addNewTask(upsertedSmartTask: UpsertedSmartTask, name: string, startHour: number, timetableLinkDay: ETimetableLinkDay) {
    let newSmartTaskToDo = this.getNewSmartTaskToDo(name, startHour, timetableLinkDay);

    const dialogRef = this.dialog.open(AddSmartTaskDialogComponent, {
      panelClass: 'app-full-bleed-dialog',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false,
      data: newSmartTaskToDo
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Close with upserted smart task
        newSmartTaskToDo = result as NewToDo;
        upsertedSmartTask.newSmartTaskToDo = newSmartTaskToDo;

        this.dialogRef.close(upsertedSmartTask);
      }
    });
  }

  private getNewSmartTaskToDo(name: string, startHour: number, timetableLinkDay: ETimetableLinkDay) {
    const newTask: NewToDoTask = {
      name: name,
      description: null,
      imageStr: null,
      orderNo: 1,
      startHour: startHour,
      startMinute: 0,
      durationInSecs: null,
      parentId: null,
      parentName: null,
      toDoId: -1,
      subTasks: [],
      expanded: false
    };

    let pattern = this.recurrenceService.getRepeatMonToFri();
    if (timetableLinkDay === ETimetableLinkDay.DayBefore) {
      pattern = this.recurrenceService.getRepeatThurToSun();
    };

    const newToDo: NewToDo  = {
      listName: null,
      listDesc: null,
      subscriberIds: [ this.input.profileId ],
      imageStr: null,
      initialCatalogId: null,
      start: null,
      recurrencePattern: pattern,
      type: EToDoType.Single,
      tasks: [ newTask ]
    };
    return newToDo;
  }
}
