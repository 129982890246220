<app-header [showNavigateBack]="true" [title]="'add-timetable.title' | translate"
  [subtitle]="'add-timetable.smarttask-subtitle' | translate" (navigateBackEvent)="navigateBack()">
</app-header>

<div class="dialog-wrapper">
  @if (profilesError() || activeToDosError()) {
  <app-error-img></app-error-img>
  }

  <form [formGroup]="form">
    <div class="sections-container">

      <!--------- Choose a child --------->
      @if (children(); as children) {
      <mat-form-field appearance="outline" color="accent" subscriptSizing="dynamic">
        <mat-label>{{ 'add-timetable.who' | translate }}</mat-label>
        <mat-select formControlName="child" style="color: #242424 !important;">
          @for (child of children; track child.id) {
          <mat-option [value]="child.id" style="color: #242424 !important;">{{ child.name }}</mat-option>
          }
        </mat-select>
        @if (form.controls.child.touched && form.controls.child.invalid && form.controls.child.errors?.['required']) {
        <mat-error>
          <span>{{ 'add-timetable.required' | translate }}</span>
        </mat-error>
        }
      </mat-form-field>
      }

      <!-- ------- Only show add timetable btn when a child has been chosen ------ -->
      @if (form.controls.child.value) {

      @if (newTimetable.schoolSubjects.length > 0) {
      <fam-button-primary [btnName]="'add-timetable.edit-timetable' | translate" [icon]="faPen"
        (buttonClickedEvent)="navigateEditTimetable()"></fam-button-primary>
      }
      @else {
      <fam-button-primary [btnName]="'add-timetable.insert-timetable' | translate" [icon]="faPlus"
        (buttonClickedEvent)="navigateEditTimetable()"></fam-button-primary>
      }
      }

      <!-- Smart tasks -->
      @if (isTimetableReady() === true) {
      <hr>
      <div class="smarttask-wrapper">

        <h2>{{ 'add-timetable.smarttask-title' | translate }}</h2>

        @if (!packingTask && !homeworkTask) {
        <p class="text">{{ 'add-timetable.text' | translate }}</p>

        <fam-button-secondary [btnName]="'add-timetable.add-smarttask' | translate" [icon]="faCircleNodes"
          (buttonClickedEvent)="navigateAddSmartTasks()"></fam-button-secondary>
        <br>
        }
        @else {
        @if (packingTask) {
        <div class="smart-task-btn" (click)="removePackBagTask()">
          <p>{{ 'add-timetable.pack-bag' | translate }}</p>
          <fa-icon style="color: green;" [icon]="faCheck" size="xl"></fa-icon>
        </div>
        }
        @else {
        <div class="smart-task-btn" (click)="navigateAddSmartTasks()">
          <p>{{ 'add-timetable.pack-bag' | translate }}</p>
        </div>
        }

        @if (homeworkTask) {
        <div class="smart-task-btn" (click)="removeHomeworkTask()">
          <p>{{ 'add-timetable.homework-reminder' | translate }}</p>
          <fa-icon style="color: green;" [icon]="faCheck" size="xl"></fa-icon>
        </div>
        }
        @else {
        <div class="smart-task-btn" (click)="navigateAddSmartTasks()">
          <p>{{ 'add-timetable.homework-reminder' | translate }}</p>
        </div>
        }

        <p class="text">{{ 'add-timetable.text-added' | translate }}</p>
        }
      </div>
      }
    </div>

    <br><br>
    <div class="save-container">
      <fam-button-cta [btnName]="'add-timetable.final-save' | translate" [disabled]="isTimetableReady() === false"
        [icon]="faFloppyDisk" (buttonClickedEvent)="submit()"></fam-button-cta>
    </div>
  </form>
</div>