<mat-toolbar color="primary" class="bottom-sheet-title">
    <span>{{ 'add-smart-task-dialogs.check-homework' | translate }}</span>
</mat-toolbar>

<div class="sheet-wrapper">

    <div class="flex-wrapper">
        <div class="btn-text-container">
            <fam-button-secondary
                [btnName]="'add-smart-task-dialogs.add-to-existing-homework' | translate"
                (buttonClickedEvent)="chooseExistingTask()"
            ></fam-button-secondary>

            <p>{{ 'add-smart-task-dialogs.add-to-existing-text-homework' | translate }}</p>
        </div>

        <div class="btn-text-container">
            <fam-button-secondary
                [btnName]="'add-smart-task-dialogs.add-new-task-homework' | translate"
                (buttonClickedEvent)="chooseNewTask()"
            ></fam-button-secondary>
            <p>{{ 'add-smart-task-dialogs.add-new-task-text-homework' | translate }}</p>
            <p>{{ 'add-smart-task-dialogs.add-new-task-text-homework2' | translate }}</p>
        </div>
    </div>
</div>