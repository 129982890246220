import { Component } from '@angular/core';
import { SmartTaskAction } from '../models/smart-task.action';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-add-smart-task-packbag-sheet',
  templateUrl: './add-smart-task-packbag-sheet.component.html',
  styleUrl: './add-smart-task-packbag-sheet.component.scss'
})
export class AddSmartTaskPackbagSheetComponent {

  constructor(
    private bottomSheetRef: MatBottomSheetRef<AddSmartTaskPackbagSheetComponent>
  ) { }

  chooseExistingTask() {
    const action: SmartTaskAction = {
      action: 'existing'
    };
    this.bottomSheetRef.dismiss(action);
  }

  chooseNewTask() {
    const action: SmartTaskAction = {
      action: 'new'
    };
    this.bottomSheetRef.dismiss(action);
  }
}
