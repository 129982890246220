import { HttpClient } from '@angular/common/http';
import { computed, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, combineLatest, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Countdown } from './countdown.data';
import { NewCountdown } from './new-countdown.data';
import { UpdatedCountdown } from './updated-countdown.data';
import { ProfileService } from '../profile/profile.service';
import { HttpErrorService } from '../error/http-error.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { ResultArray } from '../result-array.data';

@Injectable({ providedIn: 'root' })
export class CountdownService {
  private countdownsUrl = `${environment.apiUri}/v1.0/countdowns`;
  private countdownModifiedSubject = new BehaviorSubject<void>(undefined);

  private countdownsResult$ = combineLatest([
    this.countdownModifiedSubject,
    this.profileService.profileModifiedSubject
  ]).pipe(
    switchMap(() => {
      return this.http.get<Countdown[]>(this.countdownsUrl)
    }),
    map(c => ({ data: c } as ResultArray<Countdown[]>)),
    shareReplay(1),
    catchError(err => {
      console.error(this.httpErrorService.formatError(err));
      return of({ data: [], error: this.httpErrorService.formatError(err) } as ResultArray<Countdown[]>);
    })
  );
  private countdownsResult = toSignal(
    this.countdownsResult$,
    { initialValue: ({ data: [] } as ResultArray<Countdown[]>) }
  );
  countdowns = computed(() => this.countdownsResult().data);
  countdownsError = computed(() => this.countdownsResult().error);

  myCountdowns = computed(() => {
    let myCountdowns: Countdown[] = [];

    if (this.countdowns().length > 0 && this.profileService.currentProfile()) {
      myCountdowns = this.countdowns().filter(c => c.subscriberIds.some(id => id === this.profileService.currentProfile()!.id));
    }
    return myCountdowns;
  });

  constructor(
    private http: HttpClient,
    private httpErrorService: HttpErrorService,
    private profileService: ProfileService
  ) { }

  addCountdown(newCountdown: NewCountdown): Observable<Countdown> {
    return this.http.post<Countdown>(this.countdownsUrl, newCountdown).pipe(
      tap(value => {
        this.countdownModifiedSubject.next();
      }),
      catchError(this.httpErrorService.handleError)
    );
  }

  updateCountdown(countdown: Countdown): Observable<Countdown> {
    const updatedCountdown: UpdatedCountdown = {
      name: countdown.name,
      description: countdown.description,
      subscriberIds: countdown.subscriberIds,
      imageStr: countdown.imageStr,
      start: countdown.start,
      recurrencePattern: countdown.recurrencePattern
    };

    return this.http.put<Countdown>(`${this.countdownsUrl}/${countdown.id}`, updatedCountdown).pipe(
      tap(value => {
        this.countdownModifiedSubject.next();
      }),
      catchError(this.httpErrorService.handleError)
    );
  }

  deleteCountdown(countdownId: number): Observable<any> {
    return this.http.delete(`${this.countdownsUrl}/${countdownId}`).pipe(
      tap(value => {
        this.countdownModifiedSubject.next();
      }),
      catchError(this.httpErrorService.handleError)
    );
  }
}
