<mat-toolbar color="primary" class="bottom-sheet-title">
  <span>{{ 'add-calendar-sheet.create' | translate }}</span>
</mat-toolbar>

<div class="sheet-wrapper">
  <ul>
    <li><a [routerLink]="'/calendar/events/add'" (click)="openLink($event)">
      <span>{{ 'add-calendar-sheet.appointment-title' | translate }}</span>
      <span class="sub-text">{{ 'add-calendar-sheet.appointment-text' | translate }}</span>
    </a></li>

    <li><a [routerLink]="'/calendar/countdowns/add'" (click)="openLink($event)">
      <span>{{ 'add-calendar-sheet.countdown-title' | translate }}</span>
      <span class="sub-text">{{ 'add-calendar-sheet.countdown-text' | translate }}</span>
    </a></li>
    
  </ul>
</div>